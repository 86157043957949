import React from "react";
import { PaymentContextProvider } from "../../components/PaymentDetail/PaymentContext";
import Spacer from "../../components/Spacer/Spacer";
import BeeBoxStoreAdmin from "../../components/BeeBoxStoreDashboard/BeeBoxStoreAdmin";

const Admin = (props) => {
  const { parentUrl, data } = props.pageContext;
  const { labels } = data;

  return (
    <>
      <PaymentContextProvider
        labels={labels}
        parentUrl={parentUrl}
      >
        <BeeBoxStoreAdmin/>
        <Spacer size={10} />
      </PaymentContextProvider>
    </>
  );
};

export default Admin;
